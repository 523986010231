.css-um87bf-MuiPaper-root-MuiDialog-paper{
    border-radius: 16px !important;
}

.css-j882ge-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 8px !important;
}

.css-1o0kqxp-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 8px !important;
    background: #FFF !important;

}
.css-18pjc51-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color:#000 !important;
    outline: unset !important;
    border: 1px solid red !important;
    border: unset !important;
}
.css-1jk99ih-MuiInputBase-input-MuiOutlinedInput-input:focus{
    outline:unset !important;
    color:#000 !important;
}

.css-um87bf-MuiPaper-root-MuiDialog-paper{
    max-width: 352px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}