body,
html {
  margin: 0 !important;
}

.text {
  color: #fff;
}

.how-is-work {
  width: 100% !important;
  display: flex;
  /* height: 100vh; */
  justify-content: space-between; /* space between text and color */
  align-items: center;
}

.text-wrap {
  position: relative;
  width: 450px;
  padding-left: 20px;
}

.panel-text {
  font-size: 30px;
  font-weight: 400;
  color: white;
  margin: 10px 0;
}

.panel-text.active {
  font-weight: bold;
  font-size: 40px;
}

.color-display.blue {
  /* background-color: #00026d; */
}

.color-display.red {
  /* background-color: red; */
}

.color-display.orange {
  /* background-color: #cf5d00; */
}

@media (max-width: 500px){
.MuiDialog-container{
  align-items: end !important;
}
.MuiDialog-container>div{
  border-radius: 20px 20px 0px 0px !important;
  width: 100% !important;
}

.MuiDialog-container>div>div{
  max-width: 100% !important;
}
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dad9d9 !important;
  border-radius: 10px; /* Optional: Add some rounding to the track */
}
 
/* Handle (Thumb) */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px; /* Add border-radius to make the thumb rounded */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 10px; /* Maintain the border-radius on hover */
}


