body,
html {
  margin: 0 !important;
}

@media (max-width: 768px) {
  .home-wrapper{
    padding-bottom: 120px;
  }
 
}