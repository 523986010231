.whatsapp-icon {
  position: fixed !important;
  bottom: 0px;
  right: 20px;
  z-index: 1000; /* Ensure it stays above other content */
  background-color: transparent;
  border-radius: 50%;
  transition: transform 0.3s;
}

.whatsapp-icon:hover {
  transform: scale(1.1); /* Slight zoom-in on hover */
}

.whatsapp-icon svg {
  display: block;
  fill: #fff !important;
  filter: drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.12)) !important;
}

.whatsapp-icon:active {
  transform: scale(0.95); /* Slightly shrink on click */
}


@media (max-width: 899px) {
  .whatsapp-icon {
    bottom: 90px;
    right: 10px;
  }
    .whatsapp-icon svg {
        display: block;
        fill: #fff !important;
        filter: drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.12)) !important;
      }
}