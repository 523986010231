input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}


.banner-sub-title {
    width: 47% !important;
}

.banner-container {
    /* height: 500px; */
    background-color: #FFF6F6;
}

.main-container {
    display: flex;
    padding-left: 6%;
    padding-top: 10%;
    flex-wrap: wrap;

}

.left-content {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.right-content {
    flex: 1;
}

.desktopBanner {
    max-height: 500px;
}
.mobileBanner{
    display: none;
}

.benefits-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border-width: 2px 0px 2px 0px;
    border-style: solid;
    border-image-source: linear-gradient(270deg, rgba(255, 68, 94, 0) 0%, rgba(255, 68, 94, 0.3) 70.67%, rgba(255, 68, 94, 0) 100%);
    border-image-slice: 1;
    background: linear-gradient(90deg, rgba(255, 237, 237, 0) 0%, rgba(255, 237, 237, 0.619521) 22.22%, rgba(255, 237, 237, 0.8) 52.72%, rgba(255, 237, 237, 0.572982) 80.47%, rgba(255, 237, 237, 0) 100%);
    width: fit-content;
    padding: 10px;
}

.item {
    display: flex;
    align-items: center;

}

.item img {
    height: 40px;
    width: 40px;
}

.divider {
    border: 1px solid;
    /* Set a transparent solid border as a base */
    border-image-source: linear-gradient(270deg, rgba(255, 68, 94, 0) 0%, rgba(255, 68, 94, 0.3) 50.67%, rgba(255, 68, 94, 0) 100%);
    border-image-slice: 1;
    /* Ensures the gradient is applied evenly along the border */
}

.form-container {
    border: 1px solid #FFC8D0;
    border-radius: 16px;
    /* max-width: 620px; */
    background-color: #FFFFFF;
}

.availability {
    /* Background */
    background: linear-gradient(90deg,
            rgba(255, 249, 237, 0) 0%,
            rgba(255, 249, 237, 0.774402) 22.22%,
            #FFF9ED 52.72%,
            rgba(255, 249, 237, 0.716228) 80.47%,
            rgba(255, 249, 237, 0) 100%);

    /* Border */
    border-width: 2px 0 2px 2px;
    /* Top and bottom borders only */
    border-style: solid;
    border-image-source: linear-gradient(270deg,
            rgba(255, 175, 2, 0) 0%,
            rgba(255, 175, 2, 0.3) 50.67%,
            rgba(255, 175, 2, 0.3) 100%);
    border-image-slice: 1;
    /* display: flex;
    justify-content: center;
    margin-top: 2%; */
}

.availability-text {
    /* font-size: 14px;
    font-weight: 500;
    font-family: Poppins !important; */
    display: flex;
    align-items: center;
    gap: 0.4rem;
}

.availability-text img {
    width: 26px;
    height: 26px;
}


.price-section {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #FFF0F0;
}

.price-text {
    /* font-size: 16px;
    font-weight: 500;
    font-family: Poppins !important;
    padding: 1rem; */

}

.price-section span {
    font-size: 16px;
    font-weight: 500;
    font-family: Poppins !important;
    color: #BE142D;
}

.info-section {
    display: flex;
    justify-content: center;
}

.info-text {
    /* font-size: 20px;
    font-weight: 500;
    font-family: Poppins;
    font-style: normal; */
    padding: 1rem;
    text-align: center;
    color: #000000;
    /* line-height: 34px; */
}

.info-text span {
    font-size: 20px;
    font-weight: 600;
    font-family: Poppins !important;
    color: #BE142D;
    line-height: 34px;
}

.border-gradient {
    border-right: 1px solid black;
    border-image-source: linear-gradient(270deg,
            rgba(255, 68, 94, 0) 0%,
            rgba(255, 68, 94, 0.3) 50.67%,
            rgba(255, 68, 94, 0) 100%);
    border-image-slice: 1;
    
}

@media (max-width: 768px) {

    .banner-container{
        display: flex;
        flex-wrap: wrap;
      

    }
    .main-container {
        padding-left: 6%;
        padding-right: 3%;
        padding-top: 20%;
        flex-direction: column-reverse;
    }
    .form-container {
       display: none;
    }
    .left-content{
    align-items: center;
    gap: 0;
    }
  
  
     .desktopBanner{
        display: none;
     }
     /* .mobileBanner{
        max-height: 100%;
        width: 100%;
        display: block;
        background: linear-gradient(180deg, rgba(255, 246, 246, 0) 0%, #FFF6F6 68.21%);

     } */


    .benefits-container {
      flex-direction: column;
      align-items: flex-start;
      gap: 0px; 
      position: relative;
      top: -25px;
    }
    .consent-cta{
        display: none;
    }
    .availability{
        margin-bottom: 6%;
        margin-top: 0%;
        margin-right: 6%;
        border-width: 2px 0 2px 0px;
        border-image-source: linear-gradient(270deg,
            rgba(255, 175, 2, 0) 0%,
            rgba(255, 175, 2, 0.3) 50.67%,
            rgba(255, 175, 2, 0) 100%);
    }
 

    .info-text span {
        font-size: 14px;
        font-weight: 600;
        font-family: Poppins !important;
        color: #BE142D;
        line-height: 20px;
    }

    .price-section{
       margin-bottom: 4%;
       width: 100%;
       /* justify-content: center; */
    }

    .price-section span {
        font-size: 12px;
        line-height: 14px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1060px) {
    .main-container{
        flex-wrap: nowrap;
    }
    .desktopBanner {
        max-width: 100%;
    }
   
    .benefits-container {
        flex-direction: unset;
    }
}

@media screen and (min-width: 844px) and (max-width: 900px) {
    /* .main-container{
        flex-wrap: wrap-reverse;
    } */
}

@media screen and (min-width: 900px) and (max-width: 1050px) {
    .banner-sub-title {
        width: 85% !important;
    }
}

@media screen and (min-width: 1050px) and (max-width: 1350px) {
    .banner-sub-title {
        width: 70% !important;
    }
}

@media screen and (min-width: 1350px) and (max-width: 1450px) {
    .banner-sub-title {
        width: 65% !important;
    }
}

@media screen and (min-width: 1450px) and (max-width: 1550px) {
    .banner-sub-title {
        width: 60% !important;
    }
}

@media screen and (min-width: 1550px) and (max-width: 1750px) {
    .banner-sub-title {
        width: 56% !important;
    }
}