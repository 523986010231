.headerContainer {
  background: #fff !important;
  backdrop-filter: blur(6px);
  height: 84px;
  text-align: center !important;
  justify-content: center !important;
}

.callCta{
  text-decoration: none;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: "Poppins";
  border: 1px solid #FFBCC3;
  border-radius: 100px;
  min-width: 110px;
  /* height: 28px; */
  padding: 4px 4px 4px 4px;
  width: fit-content;
  
  
}
.callCta img{
  height: 20px;
  width: 20px;
}

.appLogo{
  display:block;
}

.applogo-mobile{
  display: none;
}

@media (max-width: 768px) {
  .appLogo{
    display: none;
  }
  .applogo-mobile{
    display: block;
  }
}