
.success-img{
    height: 100px;
    width: 100px;
   }

@media (max-width: 768px) {
.success-img{
 height: 70px;
 width: 70px;
}
}