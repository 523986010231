 .card{
    background: #FFFFFF;
    box-shadow: 0px 6px 20px 0px #0000001F;
    border-radius: 24px;
    padding: 24px;
    min-width: 340px;
    min-height: 400px;
    margin-top: 3%;
    margin-bottom: 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    
} 

@media screen and (max-width: 768px) {

    .card{
        padding: 24px;
        width: 312px;
        min-height: 440px;
        /* background-color: red; */
    } 
    
}


