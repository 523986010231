/* .how-heading {
  margin-top: 0% !important;
  margin-bottom: 3% !important;
} */

.how-it-works-container{
  background-color: #FFFFFF;
  /* height: 900px; */
}

.how-it-works-wrapper{
  display: flex;
  padding-left: 6%;
  padding-right: 4%;
  padding-top: 4%;
  /* align-items: center; */
}
.how-it-works-left{
  flex: 2;
}

.how-it-works-right{
   flex:1.4;
   /* margin-top: 10%; */
   display: flex;
   align-items: center;
   /* background-color: red; */
}


.hiw-content {
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative; /* Needed for positioning the dotted line */
}

.dotted-line-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.dotted-line {
  position: absolute;
  top: 0;
  left: 28px; /* Adjust to align with the icons */
  height: 85%;
  width: 2px;
  border-left: 2px dotted #F7CACA; /* Adjust color and style */
  z-index: 1; /* Ensure it stays behind the content */
}


.hiw-content img{
  width: 60px;
  height: 60px;
  z-index: 2;
}
.consent-cta-btn{
  display: none;
}

.how-it-works-right img {
  width: 100%;
  max-width: 800px; 
  height: auto; 
  object-fit: contain; 
}


@media (min-width: 768px) {
  .how-it-works-right img {
    max-width: 600px;
    height: 470px;
    margin-bottom: 10%;
  }
}

@media (min-width: 1400px) {
  .how-it-works-right img {
    max-width: 800px;
    width: auto;
    height: auto; 
    max-height: 600px;
    margin-bottom:unset;
  }
  .dotted-line-container {
    gap: 80px;
  }
}




@media (max-width: 768px) {
  .how-it-works-wrapper{
    /* display: flex; */
    padding-left: 0;
    padding-right: 0;
    padding-top: 3%;
  }
  .how-it-works-right{
     display: none  ;
  }

  .how-it-works-left{
    padding-top: 6%;
    /* padding-left: 3%;
    padding-right: 3%; */
    /* padding-bottom: 6%; */
  }

  .hiw-content{
    padding-top: 4%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* background-color: aqua; */
  }
  .hiw-content img{
    width: 32px;
    height: 32px;
  }

  .dotted-line{
    border-left:none
  }
  .dotted-line-container {
    padding: 0 6% 0 6%;
  }

  .consent-cta-btn{
    display: block;
}
}

