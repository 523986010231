.video-card{
    position: absolute;  
    top: 7%;  
    left: 4%;  
    width: 92%;  
    height: 75%;  
    /* border: 1px solid rgb(215, 161, 168);   */
    border-radius: 40px;  
    object-position: top;  
    object-fit: cover;  
}

.play-logo{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

@media (max-width: 768px) {
    .video-card{
        height: 100%;
        width: 100%;  
        top: 10%;  
        left: 0%;  
        border-radius: 24px;
    }

    .play-logo{
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
    }
}