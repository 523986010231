.mmw-container {
    background: linear-gradient(to bottom,
            #F7F8FF 0%,
            #FEF3F1 40%,
            #FFFBFA 80%,
            #FFFFFF 100%);
    height: 100vh;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 10%;
}

.top-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.logo img {
    width: 120px;
    height: 40px;
    object-fit: contain;
}

.paid-text {
    background: linear-gradient(90deg, #E6F1FF 0%, #FEEFEF 100%);
    border-width: 1px; /* Apply border to all sides */
    border-style: solid;
    border-color: rgba(173, 15, 81, 0.2); /* Use a solid border color instead of border-image */
    border-radius: 4px; /* Apply rounded corners to all sides */
    display: flex;
    gap: 10px;
    padding: 6px;
    overflow: hidden; /* Ensure content doesn't clip the border radius */
}

.paid-text img {
    height: 14px;
    width: 14px;
}

.expert-text {
    position: relative; /* Required for pseudo-element positioning */
    box-shadow: 0px 0px 2px 0px #00000040;
    background: linear-gradient(90deg, #FFECF0 0%, rgba(255, 255, 255, 0.8) 94.98%);
    border-radius: 4px;
    display: flex;
    gap: 6px;
    align-items: center;
    padding: 12px 6px 12px 6px;
    overflow: hidden; /* Ensures the border radius is visible */
}

.expert-text::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0.5px solid transparent;
    border-radius: 4px;
    background: linear-gradient(90deg, #8C2F15 21.55%, #FFECF0 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /* For WebKit browsers */
    mask-composite: exclude; /* For modern browsers */
    pointer-events: none; /* Ensures the pseudo-element doesn't block interactions */
}

.expert-text img {
    width: 68px;
    height: 36px;
}

.expert-text span {
    font-size: 12px;
    font-weight: 700;
    font-family: Lato !important;
}

.hwt-text {
    background: #EDF2FD;
    box-shadow: 0px 6px 20px 0px #0000001F;
    margin-top: 20px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.hwt-text img {
    width: 16px;
    height: 16px;
}

.how-it-works-card {
    background: #FFFFFF;
    box-shadow: 0px 6px 20px 0px #0000001F;
    border-radius: 0 0 18px 18px;
    padding: 16px;

    /* height: 40vh; */

}

.hwt-content-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.hwt-content-container img {
    width: 21px;
    height: 24px;
    margin-top: 6px;
}

.success-img{
    width: 48px;
    height: 48px;
}