.MuiPaper-root {
  --Paper-shadow: "unset" !important;
}

.faq-item-answer {
  font-size: 12px !important;
}

@media (min-width: 900px) {
  .faq-item-answer {
    font-size: 14px !important;
  }
}
