.slick-slide {
  /* outline: none !important; */
  /* width: unset !important; */
  /* max-width: unset !important; */
  /* margin: unset !important; */
}

.slick-list {
  margin: 0 -20px;
   /* Ensures spacing works across all screen sizes */
}

.slick-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}


.slick-slide > div {
  padding: 0 15px; 
  /* Adds spacing between cards */
}

.card-container .slick-slide {
  display: flex;
  justify-content: center;
}


.slick-dots{
margin-bottom: 14px;
display: flex !important;
column-gap: 0.8% !important;
justify-content: center !important;
}

.slick-dots li {
margin: 0px 0px -30px 0px !important;
  border-radius: 30px !important;
  opacity: 0.1 !important;
  background-color: #631041 !important;
  width: 6px !important;
  height: 6px !important;
}

.slick-dots > .slick-active {
    border-radius: 30px !important;
    background-color: #BE142D !important;
    width: 12px !important;
    height: 6px !important;
    opacity: 1 !important;
  }

  .slick-dots li.slick-active button:before{
    color: "unset" !important;
    content: unset !important;
  }

  .slick-dots li button:before{
    content: unset !important;
  }