.appFooterLogo{
    display:block;
    /* width: 160px; */
    height: 52px;
  }
  
  .app-footerlogo-mobile{
    display: none;
  }
  
  @media (max-width: 768px) {
    .appFooterLogo{
      display: none;
    }
    .app-footerlogo-mobile{
      display: block;
      /* width: 80px; */
      height: 34px;
    }
  }